
import { computed, defineComponent, watch } from "vue";
// import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
// import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "topbar",
  components: {
    // KTNotificationsMenu,
    // KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();
    const path = window.location.origin + "/";
    const user = computed(() => store.state.AuthModule.user);

    watch(user, () => {
      // console.log(user.value.user);
    });

    return {
      user,
      path,
    };
  },
});
