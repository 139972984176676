const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Resume",
        route: "/resume",
        target: "resume",
        svgIcon: "other/svg/vuesax-bold-graph.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Heatmaps",
        route: "/heatmaps",
        target: "heatmaps",
        svgIcon: "other/svg/vuesax-bold-map.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Peta Zona",
        route: "/zone-map",
        target: "zone-map",
        svgIcon: "other/svg/vuesax-bold-radar-1.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Peta Sebaran Kader",
        route: "/kader-map",
        svgIcon: "other/svg/vuesax-bold-map-2.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Komparasi Peta Suara",
        route: "/map-comparison",
        target: "map-comparison",
        svgIcon: "other/svg/vuesax-bold-picture-frame.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "KTANisasi",
        route: "/ktanisasi/caleg",
        target: "ktanisasi",
        svgIcon: "other/svg/vuesax-bold-money-tick.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Sapa Rakyat Door to Door",
        route: "/person-map/pro",
        target: "person-map",
        svgIcon: "other/svg/vuesax-bold-security-user.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Sapa Rakyat Door to Door",
        route: "/movement-map/map",
        target: "movement-map",
        svgIcon: "other/svg/vuesax-bold-routing.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Laporan Pelanggaran",
        route: "/violation-map",
        target: "violation-map",
        svgIcon: "other/svg/vuesax-bold-alarm.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Hot Issue",
        route: "/issue-map",
        target: "issue-map",
        svgIcon: "other/svg/vuesax-bold-clipboard-text.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Advokasi oleh Aleg",
        route: "/advokasi-map",
        target: "advokasi-map",
        svgIcon: "other/svg/judge.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Atributisasi",
        route: "/attribute-map",
        target: "attribute-map",
        svgIcon: "other/svg/vuesax-bold-flag.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Tim Pengamanan Suara",
        route: "/tps-map",
        target: "tps-map",
        svgIcon: "other/svg/Icon material-people.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Hasil Pemilu",
        route: "/result/pks",
        target: "result",
        svgIcon: "other/svg/vuesax-bold-chart.svg",
        fontIcon: "bi-layers",
      },
    ],
  },
];

export default DocMenuConfig;
